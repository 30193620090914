import RulerCreator from './RulerCreator';
import RulerDataGrid from './RulerDataGrid';
import Page from '../../components/Page';

function RulerPage(){
    /// Ruler Page
    return(
        <Page title="Rulers">
            <RulerCreator/>
            <RulerDataGrid/>
        </Page>
    )
};

export default RulerPage;