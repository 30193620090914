import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../components/Toast';
import getToken from '../../mixins/GetToken';
import SplitToken from '../../mixins/SplitToken';

function* createCompany(action) {
    const user = SplitToken();
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let company = yield axios.post(`/api/Company/CreateCompany/${action.payload.userId}/${action.payload.roleId}`, action.payload.company, config);
        if (company.status === 200) {
            Toast.fire({
                title: 'Company created',
                icon: 'success'
            })
        }
        else {
            return Toast.fire({
                title: 'Creation failed',
                icon: 'error'
            })
        }
    } catch (error) {
        console.log(error)
    }
    try {
        let allCompanies = yield axios.get(`/api/Company/GetAllCompaniesForUser/${action.payload.userId}`, config);
        yield put({
            type: "USER_COMPANIES",
            payload: allCompanies.data
        })
    } catch (error) {
        console.log(error)
    }
}

function* usersInCompany(action) {
    const user = SplitToken();
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        // console.log("action",action.payload)
        let users = yield axios.get(`/api/Company/GetAllUsersForCompany/${action.payload.event}`, config);
        if (action.payload.type == 1){
        yield put({
            type: "SET_COMPANY_USERS",
            payload: users.data
        })
        } else if (action.payload.type == 2){
        yield put({
            type: "SET_MENU_COMPANY_USERS",
            payload: users.data
        })
        };
    } catch (error) {
        console.log(error)
    }
}

function* getAllCompanies(action) {
    const user = SplitToken();
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        // console.log("action",action.payload)
        let allCompanies = yield axios.get(`/api/Company/GetAllCompanies`, config);
        yield put({
            type: "SET_ALL_COMPANIES",
            payload: allCompanies.data
        })
    } catch (error) {
        console.log(error)
    }
}

function* addUserToCompany(action) {
    const user = SplitToken();
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const addUser = yield axios.post('/api/Account/AddUserToGroup', action.payload, config)
        if (addUser.status === 202) Toast.fire({ title: 'User Added', icon: 'success' });
        else Toast.fire({ title: addUser.data, icon: "error" });
        yield put({ type: "GET_COMPANY_USERS", payload: {event:action.payload.companyId, type:1} });
    } catch (error) {
        console.log(error)
    }
}

function* newUserToCompany(action) {
    const user = SplitToken();
    const token = getToken();
    const { companyId, usr } = action.payload;

    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const newUser = yield axios.post(`/api/Account/CreateUser?companyId=${companyId}`, usr, config);
        if (newUser.status === 200) { 
            const newRole = yield axios.post('/api/Account/AddUserToGroup', action.payload.role, config);
            if (newRole.status === 200) Toast.fire({ title: 'User added', icon: 'success' });
            yield put({ type: "GET_COMPANY_USERS", payload: {event:action.payload.role.companyId , type:1}});
        }
    } catch (error) {
        console.log(error)
        Toast.fire({ title: 'Failed creating user, email may be already in use', icon: 'error' });
    }
}

function* getProsCompany(action){
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        const prosCompany = yield axios.get('/api/Company/GetProcessorCompany', config);
        // console.log("HERE", prosCompany.data)
        if (prosCompany.status === 200){
            yield put({
                type: "SET_ALL_PROS_COMPANY",
                payload: prosCompany.data
            })
        }
    } catch(error){
        console.log(error)
    }
}

function* updateCompanyStatus(action){
    const user = SplitToken();
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        console.log(action.payload)
        const updateCompany = yield axios.put('/api/Company/UpdateCompany', action.payload, config);
        if (updateCompany.status === 200){
            //refreshes all availible company processors
            getProsCompany();

            let allCompanies = yield axios.get(`/api/Company/GetAllCompaniesForUser/${user.id}`, config);
            yield put({
                type: "USER_COMPANIES",
                payload: allCompanies.data
            });
            yield put({
                type: "GET_COMPANY",
                payload: action.payload.Id
            });      
            console.log("HERE", allCompanies.data);
        }
    } catch(error){
        console.log(error)
    }
}

function* updateCompanyObject(action){
    const user = SplitToken();
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    console.log("SAGA", action.payload)
    try{
        const updatedCompany = yield axios.put('/api/Company/UpdateCompany', action.payload, config);
        if (updatedCompany.status === 200){
            yield put({
                type: "GET_ALL_COMPANIES",
            })   
            let allCompanies = yield axios.get(`/api/Company/GetAllCompaniesForUser/${user.id}`, config);
            yield put({
                type: "USER_COMPANIES",
                payload: allCompanies.data
            });         
            yield put({
                type: "GET_COMPANY",
                payload: action.payload.Id
            });         
        //     // action.handleClose1();
            Toast.fire({
                title: 'Company Updated',
                icon: 'success'
            })
        }
    } catch(error){
        console.log(error)
        Toast.fire({
            title: 'Company Not Updated',
            icon: 'error'
        })
    }
}

function* fetchMessagesforCompany(action){
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let allCompanyMessages = yield axios.get(`/api/Message/GetAllMessagesForCompany/${action.payload}`, config);
        yield put({
            type: "COMPANIES_MESSAGES",
            payload: allCompanyMessages.data
        });
        // console.log("HERE", allCompanyMessages.data)

    } catch(error){
        console.log(error)
        // return Toast.fire({
        //     title: 'Update Failed',
        //     icon: 'error'
        // })
    }
}

function* createPrice(action) {
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let price = yield axios.post(`/api/Company/CreatePrice`, action.payload, config);
        if (price.status === 200) {
            Toast.fire({
                title: 'Price Updated',
                icon: 'success'
            })
            yield put({
                type: "GET_PRICES",
                payload: action.payload.CompanyId
            });
        }
        else {
            return Toast.fire({
                title: 'Creation failed',
                icon: 'error'
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* UpdatePrice(action) {
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let price = yield axios.put(`/api/Company/UpdatePrice`, action.payload, config);
        if (price.status === 200) {
            Toast.fire({
                title: 'Price Updated',
                icon: 'success'
            })
            yield put({
                type: "GET_PRICES",
                payload: action.payload.CompanyId
            })
        }
        else {
            return Toast.fire({
                title: 'Creation failed',
                icon: 'error'
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* fetchPricesforCompany(action){
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let allCompanyPrices = yield axios.get(`/api/Company/GetAllPricesForCompany/${action.payload}`, config);
        yield put({
            type: "COMPANY_PRICES",
            payload: allCompanyPrices.data
        });
        console.log("Prices", allCompanyPrices.data)

    } catch(error){
        console.log(error)
    }
}

function* fetchPricesforBotmark(action){
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let allBotmarkPrices = yield axios.get(`/api/Company/GetBotmarkPrice`, config);
        yield put({
            type: "BOTMARK_PRICES",
            payload: allBotmarkPrices.data
        });
    } catch(error){
        console.log(error)
    }
}

function* deleteMessage(action){
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    console.log(action.payload)
    try{
        let deleteThisMessage = yield axios.delete(`/api/Message/DeleteMessage/${action.payload.id}`, config);
        if (deleteThisMessage.status === 200) {
            Toast.fire({
                title: 'Deleted',
                icon: 'success'
            })
            yield put({
                type: "FETCH_MESSAGES",
                payload: action.payload.companyId
            });
        }
        else {
            return Toast.fire({
                title: 'Creation failed',
                icon: 'error'
            })
        }
    } catch(error){
        console.log(error)
    }
}

function* deletePrice(action){
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let deleteThisPrice = yield axios.delete(`/api/Company/DeletePrice/${action.payload.id}`, config)
        if (deleteThisPrice.status === 200) {
            Toast.fire({
                title: 'Deleted',
                icon: 'success'
            })
            yield put({
                type: "GET_PRICES",
                payload: action.payload.CompanyId
            });
        }
        else {
            return Toast.fire({
                title: 'Delete failed',
                icon: 'error'
            })
        }
    } catch(error){
        console.log(error)
    }
}

function* getCompany(action){
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let gotCompany = yield axios.get(`/api/Company/GetCompany/${action.payload}`, config)
        console.log("SAGA",gotCompany.data)
        if (gotCompany.status === 200) {
            yield put({
                type: "SET_SELECTED_COMPANY",
                payload: gotCompany.data
            });
        }
        else {
            return Toast.fire({
                title: 'Error finding Company',
                icon: 'error'
            })
        }
    } catch(error){
        console.log(error)
    }
}

function* selectedCompanyUsers(action) {
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let companyUsers = yield axios.get(`/api/Company/GetAllUsersForCompany/${action.payload.event}`, config);
            yield put({
                type: "SET_SELECTED_COMPANY_USERS",
                payload: companyUsers.data
            })
    } catch (error) {
        console.log(error)
    }
};

function* delistRelistCompany(action) {
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let companyUsers = yield axios.put(`/api/Company/DelistRelistCompany/${action.payload}`, config);
        if (companyUsers.status == 200){
            yield put({
                type:"GET_COMPANY",
                payload: action.payload
            })
            yield put({
                type:"GET_ALL_COMPANIES",
            })
            yield put({
                type:"SET_SELECTED_COMPANY",
                payload: {}
            })
            yield put({
                type:"SET_SELECTED_COMPANY_USERS",
                payload: []
            })
            yield put({
                type:"SET_ALL_RULERS",
                payload: []
            })
            yield put({
                type:"SET_ALL_LICENSES",
                payload: []
            })
        }
    } catch (error) {
        console.log(error)
    }
};

function* companySaga() {
    yield takeLatest("CREATE_COMPANY", createCompany);
    yield takeLatest("GET_COMPANY_USERS", usersInCompany);
    yield takeLatest("ADD_USER_TO_COMPANY", addUserToCompany);
    yield takeLatest("NEW_USER_TO_COMPANY", newUserToCompany);
    yield takeLatest("GET_ALL_PROCESSOR_COMPANY", getProsCompany);
    yield takeLatest("CHANGE_COMPANY_STATUS", updateCompanyStatus);
    yield takeLatest("FETCH_MESSAGES", fetchMessagesforCompany);
    yield takeLatest("POST_PRICE", createPrice);
    yield takeLatest("UPDATE_PRICE", UpdatePrice);
    yield takeLatest("GET_PRICES", fetchPricesforCompany);
    yield takeLatest("FIND_BOTMARK_PRICES", fetchPricesforBotmark);
    yield takeLatest("DELETE_MESSAGES", deleteMessage);
    yield takeLatest("DELETE_PRICE", deletePrice);
    yield takeLatest("GET_ALL_COMPANIES", getAllCompanies);
    yield takeLatest("UPDATE_COMPANY_OBJECT", updateCompanyObject);
    yield takeLatest("GET_COMPANY", getCompany);
    yield takeLatest("GET_SELECTED_COMPANY_USERS", selectedCompanyUsers);
    yield takeLatest("DELIST_RELIST_COMPANY", delistRelistCompany);
}

export default companySaga;