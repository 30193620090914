import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Modal,
    Chip,    
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Tabs,
    Tab,
    TextField,
    OutlinedInput,
    Autocomplete
} from '@mui/material';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';
import {
    Wifi,
    WifiOff,
    Circle,
    CheckCircle
} from 'react-feather';
import CompanyStatusChange from './CompanyStatusChange';
import CompanyLicenseDataGrid from './CompanyLicenseView';
import RulerCreator from '../rulers/RulerCreator';
import CompanyRulerDataGrid from './CompanyRulerView';
import CurrencyFormat from 'react-currency-format';
import CompanyRate from './CompanyRate';
import CompanyInfoEditor from './CompanyInfoEditor';
import CompanyUserView from './CompanyUserView';
import CompanyLicensingCreation from './CompanyLicenseCreator';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const CompanyViewer = () => {
    /// Company data grid for super admins shows all registered companies
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const role = useSelector((state) => state.CompanyReducer.userRole);
    const users = useSelector((state) => state.CompanyReducer.usersInCompany);
    const allCompanies = useSelector((state) => state.CompanyReducer.allCompanies);
    const companyData = useSelector((state) => state.CompanyReducer.selectedCompany);
    const [selectedComp, setSelectedComp] = React.useState('00000000-0000-0000-0000-000000000000');
    const [selectedCompany, setSelectedCompany] = React.useState();
    const [rowItem, setRowItem] = useState();
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [processStatus, setProcessStatus] = React.useState(companyData?.processExternal);
    const [companyRate, setCompanyRate] = React.useState(companyData?.companyRate);

    const defaultProps = {
        options: allCompanies,
        getOptionLabel: (option) => option.name,
    };

    function editStatus(company) { // hadles the double click to bring up modal to edit crop amount
        setRowItem({
            id: company.id,
            name: company.name,
            street: company.street,
            city: company.city,
            state: company.state,
            zip: company.zip,
            country: company.country,
            processExternal: company.processExternal,
            email: company.email,
            accountingEmail: company?.accountingEmail,
            phoneNumber: company.phoneNumber,
            companyStatus: company.companyStatus,
            companyRate: company.companyRate
        })
        handleOpen1();
    };

    let company = {
        Id: companyData?.id,
        Name: companyData?.name,
        City: companyData?.city,
        Country: companyData?.country,
        State: companyData?.state,
        Street: companyData?.street,
        Zip: companyData?.zip,
        ProcessExternal: processStatus,
        Email: companyData?.email,
        PhoneNumber: companyData?.phoneNumber,
        CompanyStatus: processStatus,
        AccountingEmail: companyData?.accountingEmail,
        CompanyRate: companyRate
    };

    /// change company processing status
    function changeProcessExternalStatus(){
        if (companyData?.processExternal === false) {
            setProcessStatus(true);
            company = {
                Id: companyData?.id,
                Name: companyData?.name,
                City: companyData?.city,
                Country: companyData?.country,
                State: companyData?.state,
                Street: companyData?.street,
                Zip: companyData?.zip,
                ProcessExternal: true,
                Email: companyData?.email,
                PhoneNumber: companyData?.phoneNumber,
                CompanyStatus: companyData?.companyStatus,
                AccountingEmail: companyData?.accountingEmail,
                CompanyRate: companyData?.companyRate
            }
            if ( company?.ProcessExternal === true){
                dispatch({ type: "CHANGE_COMPANY_STATUS", payload: company});
            }
        }
        else if (companyData?.processExternal === true) {
            setProcessStatus(false)
            company = {
                Id: companyData?.id,
                Name: companyData?.name,
                City: companyData?.city,
                Country: companyData?.country,
                State: companyData?.state,
                Street: companyData?.street,
                Zip: companyData?.zip,
                ProcessExternal: false,
                Email: companyData?.email,
                PhoneNumber: companyData?.phoneNumber,
                CompanyStatus: companyData?.companyStatus,
                AccountingEmail: companyData?.accountingEmail,
                CompanyRate: companyData?.companyRate
            }
            if ( company?.ProcessExternal === false){
                dispatch({ type: "CHANGE_COMPANY_STATUS", payload: company});
            }        
        }
    }

    const handleOpen = () => setOpen(!open);
    const handleCheck = () => setCheck(!check);

    function fetchCompany(){
        dispatch({ type: 'GET_COMPANY', payload: selectedComp});
        dispatch({ type: 'GET_RULERS_FOR_COMPANY', payload: selectedComp});
        dispatch({ type: 'GET_LICENSES', payload: {companyId: selectedComp, type: '1'} });
        dispatch({ type: 'GET_SELECTED_COMPANY_USERS', payload: {event:selectedComp}});
    };

    useEffect(() => {
        dispatch({ type: 'GET_ALL_COMPANIES' });
        dispatch({ type: 'SET_SELECTED_COMPANY', payload: {}});
        dispatch({ type: 'SET_ALL_RULERS', payload: []});
        dispatch({ type: 'SET_ALL_LICENSES', payload: []});
        dispatch({ type: 'SET_SELECTED_COMPANY_USERS', payload: []});
    }, []);

    const [value, setValue] = React.useState(0);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                {...other}
            >
                <Box p={3}>
                    {children}
                </Box>
            </div>
        )
    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    // function companySelect(event){
    //     if (selectedComp != '00000000-0000-0000-0000-000000000000'){
    //         for (let i = 0; i < allCompanies.length; i++){
    //             if (selectedComp == allCompanies[i].id){
    //                 setSelectedCompany(allCompanies[i]);
    //                 setProcessStatus(allCompanies[i].processExternal)
    //             }
    //         }
    //     } else {
    //         setSelectedCompany();
    //         setProcessStatus();
    //     }
    // }

    function delistCompany(){
        dispatch({ type: 'DELIST_RELIST_COMPANY', payload: selectedComp});
    }

    return (
        <Page title="Companies">
            <Box p={3} sx={{ '& .header': { backgroundColor: '#e2e2e2' } }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h3' textAlign='left' p={2}>
                        Company Management
                    </Typography>
                </Box>
                <div style={{justifySelf:'left', display:'flex', flexDirection:'row', width:'50%'}}>
                    <FormControl style={{width:'50%', margin:'2px'}}>
                        {/* <InputLabel id="demo-simple-select-label">Company</InputLabel> */}
                        {/* <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedComp}
                            label="Company"
                            onChange={(event) => setSelectedComp(event.target.value)}
                        > */}
                            {/* <MenuItem value={'00000000-0000-0000-0000-000000000000'}>None</MenuItem> */}
                            {/* {allCompanies.map((item) =>(
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select> */}
                            {/* <Autocomplete
                                sx={{ width: 300 }}
                                // disablePortal
                                disableClearable={true}
                                disableCloseOnSelect={true}
                                {...defaultProps}
                                // value={selectedComp}
                                options={allCompanies}
                                // isOptionEqualToValue={(option,value) => option === value}

                                // getOptionLabel={ (option) => option.name ? option.name : ""}
                                // renderOption={(props, option, { selected }) => {
                                //     const { key, ...otherProps } = props;
                                    
                                //     return (
                                //         <li key={key} {...otherProps} style={{ paddingLeft: 5 }}>
                                //             {"- ".repeat(option.level)}{option.name}
                                //         </li>
                                //     );
                                // }}

                                renderInput={(params) =>(
                                    <TextField 
                                        value={params.id} 
                                        {...params} 
                                        label="Companies" 
                                    />
                                )}
                                onChange={(event,newValue) => {setSelectedComp(newValue?.id), setSelectedCompany(newValue)}}
                                // onChange={(event,newValue) => setSelectedCompany(newValue)}
                            /> */}
                            <Autocomplete
                                disablePortal
                                {...defaultProps}
                                // sx={{ width: 300 }}
                                renderInput={(params) => <TextField value={params.id} {...params} label="Companies" />}
                                // value={companySelection}
                                onChange={(event,newValue) => {setSelectedComp(newValue?.id), setSelectedCompany(newValue)}}
                            />
                    </FormControl>
                    <Button variant='contained' style={{width:"15%", height:'75%', alignSelf:'center', margin:'2px'}} onClick={()=>{fetchCompany()}}>Search</Button>
                </div>
                <Tabs value={value} onChange={handleTabChange} left style={{ borderBottom: '2px solid darkGray', paddingBottom: '10px' }}>
                    <Tab label="Company Info" />
                    <Tab label="Company Users" />
                    <Tab label="Licensing" />
                    <Tab label="Rulers"/>
                    <Tab label="Generate License/ Ruler"/>
                </Tabs>
                <TabPanel value={value} index={0} style={{textAlign:'left'}}>
                    {/* {companyData?.deleted == null ? */}
                        <h2>{companyData?.name}</h2>
                        {/* : */}
                        {/* <h2 style={{color:'red'}}>{companyData?.name}: Company Currently Disabled</h2> */}
                    {/* // } */}
                    <div style={{display:'flex'}}>
                        <CompanyInfoEditor/>
                        <div style={{width:'50%', margin:5}}>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <Typography>
                                    <b>Company Credit Status:</b>
                                    { companyData?.companyStatus === 0 &&
                                        <Button className="statusButton" color="primary" onClick={() => editStatus(companyData)}>Pending</Button>
                                    } 
                                    { companyData?.companyStatus === 1 &&
                                        <Button className="statusButton" color="success" onClick={() => editStatus(companyData)}>Approved with Invoice</Button>
                                    }
                                    { companyData?.companyStatus === 2 &&
                                        <Button className="statusButton" color="warning" onClick={() => editStatus(companyData)}>Approved with Credit Card</Button>
                                    }
                                    { companyData?.companyStatus === 3 &&
                                        <Button className="statusButton" color="error" onClick={() => editStatus(companyData)}>Denied</Button>
                                    }
                                </Typography>
                            </div>
                            <br/>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <CompanyRate/>
                            </div>
                            <br/>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <span>If company is capable of processing plans, for self or for customers?</span>
                                <br/>
                                <br/>
                                { companyData?.processExternal === true ?
                                    <Chip icon={<Wifi/>} variant="contained" color="primary" label="For Customers"/>
                                    :
                                    <Chip icon={<WifiOff/>} variant="contained" color="secondary" label="For Self"/>
                                }
                                <div>
                                    <p>To make this company visible to prospective customers, make sure the box below is checked.</p>
                                    <Form>
                                        <Label check>
                                            <Input type="switch" role="switch" checked={companyData?.processExternal} value={companyData?.processExternal} onChange={() => changeProcessExternalStatus()}/>
                                            Processing External Plans
                                        </Label>
                                    </Form>
                                </div>
                            </div>
                            <br/>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <span>Is this company signed up for auto approval of estimates for credit payments?</span>
                                <br/>
                                <br/>
                                { companyData?.autoApproveEstimate === true ?
                                    <Chip icon={<CheckCircle/>} variant="contained" color="success" label="Auto Approve Estimate"/>
                                    :
                                    <Chip icon={<Circle/>} variant="contained" color="primary" label="Manual Approve Estimate"/>
                                }
                            </div>
                            <br/>
                            <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
                                <Typography>To "Delete" a Company select this option. This will not completely delete the company or users but will instead disable the company and all users under it.</Typography>
                                <Button variant='contained' color="error" style={{width:"50%", alignSelf:'center', margin:'2px'}} onClick={()=>{delistCompany()}}>Delist Company</Button>
                            </div>
                        </div>
                    </div>
                    <Modal
                        open={open1}
                        onClose={handleClose1}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <CompanyStatusChange 
                            item={rowItem} 
                            style={style}
                            handleClose1={handleClose1}
                        />
                    </Modal> 
                </TabPanel>
                <TabPanel value={value} index={1} style={{}}>
                    <CompanyUserView/>
                </TabPanel>
                <TabPanel value={value} index={2} style={{}}>
                    <CompanyLicenseDataGrid/>
                </TabPanel>
                <TabPanel value={value} index={3} style={{}}>
                    <CompanyRulerDataGrid/>
                </TabPanel>
                <TabPanel value={value} index={4} style={{}}>
                    <div style={{flexDirection:"row", display:'flex'}}>
                        <div style={{width:"50%", border:'solid 2px darkgray', margin:2, borderRadius:5, padding:5}}>
                            <CompanyLicensingCreation selectedCompProp={selectedComp} />
                        </div>
                        <div style={{width:"50%", border:'solid 2px darkgray', margin:2, borderRadius:5, padding:5}}>
                            <RulerCreator selectedCompProp={selectedComp} />
                        </div>
                    </div>
                </TabPanel>
            </Box>
        </Page>
    )
};

export default CompanyViewer;