import React from "react";
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Alert,
    AlertTitle
} from "@mui/material";
import Upload from "./Upload";
import LoadingReport from "./Loading";
import Toast from "../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import ReviewPage from "../billing/Validation";
import PaymentPage from "../billing/Checkout";
import moment from "moment";
import Page from '../../components/Page';

const FileUploader = () => {
    const navigate = useNavigate();
    /// File Upload page
    const user = useSelector((state)=> state.UserReducer.user);
    const customerSelect = useSelector((state) => state.UserReducer.customerSelection);
    console.error("CUSTOMER", customerSelect)

    const [clientName, setClientName] =  React.useState('');
    const [jobName, setJobName] =  React.useState('');
    const [areaName, setAreaName] =  React.useState('');
    const [floorName, setFloorName] =  React.useState('');
    const [planName, setPlanName] =  React.useState('');
    const [fileName, setFileName] = React.useState(`NewPlan_${moment().format('YY-MM-D_h:mm:ssa')}`)
    const [file, setFile] = React.useState([]);
    const [review, setReview] = React.useState(false);
    const [pay, setPay] = React.useState(false);
    const loading = useSelector((state) => state.FileReducer.isLoading);
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const validation = useSelector((state) => state.FileReducer.file);
    const dispatch = useDispatch();
    const pdf = useSelector((state)=> state.FileReducer.PDFfile.file);
    const [pdfUrl, setPdfUrl] = React.useState(`${pdf}`);
    const [noProcess, setNoProcess] = React.useState(false);

    React.useEffect(() => {
        dispatch({ type: 'GET_PDF_FILE', payload: 1, setPdfUrl });
        if (validation.file.length > 0) {
            setReview(true)
        }
    }, [validation.file]);

    const defaultFile = "NewPlan";
    
    const handleUpload = () => {
        if (user.hasAcceptedUploadPolicy === 0){

        } else
        {
            if(jobName === "" && noProcess == true){
            // if(!fileName?.includes(defaultFile) && jobName === ""){
                swal({
                    text: 'Job Name required, please enter a job name.',
                    icon: "error"
                });
                return;
            } else if (floorName === "" && noProcess == true){
            // } else if (!fileName?.includes(defaultFile) && floorName === ""){
                swal({
                    text: 'Floor level required, please enter a floor level.',
                    icon: "error"
                });
                return;
            } else{
                if ( file.length < 1 ) {
                    return Toast.fire({ title: 'File required', icon: 'error' });
                } else if(noProcess == true){
                    if(customerSelect != '00000000-0000-0000-0000-000000000000'){
                        dispatch({ type: 'UPLOAD_FILE_NO_PROCESS', payload: {file: file[0], name: fileName, companyId: company.id, customerId: customerSelect}, setFile, setClientName, setJobName, setAreaName, setFloorName, setPlanName, setFileName, navigate });
                    } else {
                        swal({
                            text: 'Customer missing, please Assign a customer to proceed.',
                            icon: "error"
                        });
                    }
                }
                else dispatch({ type: 'UPLOAD_FILE', payload: {file: file[0], name: fileName, companyId: company.id} })
            }
        }
    }

    return (
        <Page title="Upload">
            <Box p={4} sx={{ height: '100vh' }}>
                <Box p={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', flexDirection: 'column' }}>
                    { company === undefined ? 
                        <Alert severity="error">
                            Please create a company or ask your admin to invite you.
                        </Alert>
                        : ""
                    }
                    { loading ? <LoadingReport/> : <Upload noProcess={noProcess} setNoProcess={setNoProcess} clickedpdfUrl={pdfUrl} setClientName={setClientName} setJobName={setJobName} setAreaName={setAreaName} setFloorName={setFloorName} setPlanName={setPlanName} setFile={setFile} handleUpload={handleUpload} setFileName={setFileName} clientName={clientName} jobName={jobName} areaName={areaName} floorName={floorName} planName={planName}/> }
                </Box>
                <ReviewPage status={review} change={setReview} pay={setPay}/>
                <PaymentPage status={pay} change={setPay}/>
            </Box>
        </Page>
    )
}

export default FileUploader;