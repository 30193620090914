import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../components/Toast';
import getToken from '../../mixins/GetToken';
import { useNavigate } from 'react-router-dom';
import SplitToken from '../../mixins/SplitToken';

function* createLicense(action) {
    const user = SplitToken();
    const token = getToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let license = yield axios.post(`/api/Account/PostLicense`, action.payload, config);
        if (license.status === 200) {
            Toast.fire({
                title: `${action.payload.LicenseQuantity} License(s) Generated`,
                icon: 'success',
            })
            action.setNotes();
            action.setTimeLength();
            action.setQuant();
            action.setExpirationTime(false);
            yield put({
                type: "GET_LICENSES",
            });
        }
        else {
            return Toast.fire({
                title: 'Creation failed',
                icon: 'error',
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* fetchAllLicenses(action){
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let allLicenses = yield axios.get(`/api/Account/FetchLicenses/${action.payload.companyId}/${action.payload.type}`, config);
        yield put({
            type: "SET_ALL_LICENSES",
            payload: allLicenses.data
        });
    } catch(error){
        console.log(error)
    }
}

function* updateLicense(action){
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    console.error(action.payload)
    try{
        let updateLicense = yield axios.put(`/api/Account/UpdateLicense`, action.payload, config);
        if (updateLicense.status === 200) {
            Toast.fire({
                title: `License Notes Updated`,
                icon: 'success',
            })
            yield put({ type: "GET_LICENSES", payload: {companyId: action.companyId, type: '2'} })
        }
    } catch(error){
        console.log(error)
    }
}

function* updateSelectedCompanyLicense(action){
    const token = getToken();
    const userToken = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        let updateLicense = yield axios.put(`/api/Account/UpdateLicense`, action.payload, config);
        if (updateLicense.status === 200) {
            Toast.fire({
                title: `License Notes Updated`,
                icon: 'success',
            })
            yield put({ type: "GET_LICENSES", payload: {companyId: action.companyId, type: '1'} })
        }
    } catch(error){
        console.log(error)
    }
}

function* LicenseSaga() {
    yield takeLatest("CREATE_LICENSE", createLicense);
    yield takeLatest("GET_LICENSES", fetchAllLicenses);
    yield takeLatest("UPDATE_LICENSE", updateLicense);
    yield takeLatest("UPDATE_SELECTED_COMPANY_LICENSE", updateSelectedCompanyLicense);
}

export default LicenseSaga;