import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete
} from "@mui/material";
import { Form, FormGroup, Label, Input, FormText, Dropdown } from 'reactstrap';

function RulerCompanyModal({item, style}){
  const dispatch = useDispatch();
  /// displays message from customer for processor
//   const [infoNotes, setInfoNotes] = React.useState(item.rulerNotes);
  const allCompanies = useSelector((state) => state.CompanyReducer.allCompanies);
  const [selectedComp, setSelectedComp] = React.useState(item.userId);
  console.error(item)

  let rulerInfo = {
    Id: item.id,
    RulerNotes: item.rulerNotes,
    RulerSerialNumber: item.rulerSerialNumber,
    userId: selectedComp,
    WarrantyDate: item.warrantyDate,
  }

  function submitCompany(){
    dispatch({ type: "UPDATE_RULER", payload: rulerInfo });

    setSelectedComp(item.userId);
  };

  const defaultProps = {
    options: allCompanies,
    getOptionLabel: (option) => option.name,
  };

  React.useEffect(() => {

  },[]);

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
          <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
              Ruler Company Assignment
            </Typography>
            <p style={{textAlign:'center'}}>{item.rulerSerialNumber}</p>
            <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
                    <FormControl style={{width:'100%', margin:'2px'}}>
                        {/* <InputLabel id="demo-simple-select-label">Company</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedComp}
                            label="Company"
                            onChange={(event) => setSelectedComp(event.target.value)}
                        > */}
                            {/* <MenuItem value={'00000000-0000-0000-0000-000000000000'}>None</MenuItem> */}
                            {/* {allCompanies.map((item) =>(
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select> */}
                        <Autocomplete
                            disablePortal
                            {...defaultProps}
                            renderInput={(params) => <TextField value={params.id} {...params} label="Companies" />}
                            onChange={(event,newValue) => setSelectedComp(newValue?.id)}
                        />
                </FormControl>
                <Button onClick={() => submitCompany()}>Update Company</Button>
            </div>
          </Box>
        </Box>
    )
}

export default RulerCompanyModal;