import LicenseDataGrid from './LicenseDataGrid.jsx';
import LicensingCreation from './licensing.jsx';
import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';

function LicensingPage (){
    const dispatch = useDispatch();
    /// licensing Page
    useEffect(() => {
        dispatch({ type: 'GET_LICENSES', payload: {companyId: "00000000-0000-0000-0000-000000000000", type: '2'} });
        dispatch({ type: 'GET_ALL_COMPANIES' })
    }, []);

    return(
        <Page title="Licensing">
            <div style={{width: "100%", height: "100%"}}>
                <LicensingCreation/>
                <LicenseDataGrid/>
            </div>
        </Page>
    )
}

export default LicensingPage;