export const defaultChoice = { value: -1, name: "Select one" }
export const admin = { value: 0, name: "Admin" };
export const planner = { value: 1, name: "Planner" };
export const download = { value: 2, name: "Download" };
export const downloaderAdmin = { value: 3, name: "Downloader Admin" };
export const billing = { value: 4, name: "Billing" };
export const receivedBilling = { value: 5, name: "Received Billing" };
export const plannerDownload = { value: 6, name: "Planner Downloader" };
export const subSuperAdmin = { value: 7, name: "Sub Super Admin" };

export const adminRoleSet = [ defaultChoice, admin, planner, plannerDownload, billing, receivedBilling ];
export const downloaderAdminRoleSet = [ defaultChoice, download, billing, downloaderAdmin ];
export const subSuperAdminRoleSet = [defaultChoice, subSuperAdmin, admin, planner, plannerDownload, billing, receivedBilling ];
export const superAdminRoleSet = [defaultChoice, subSuperAdmin, admin, planner, plannerDownload, billing, receivedBilling, downloaderAdmin, download, billing, ]

export function determineRoleOptions(userRole, authenticated) {
    console.log(authenticated)
    if (userRole === "AD" && authenticated.isAdmin == false) {
        return adminRoleSet;
    }
    else if (userRole === "SSA" && authenticated.isAdmin == false) {
        return subSuperAdminRoleSet;
    }
    else if (userRole === "DAD" && authenticated.isAdmin == false) {
        return downloaderAdminRoleSet;
    }
    else if ((userRole === "AD" && authenticated.isAdmin == true)) {
        return superAdminRoleSet;
    }
    else if ((userRole === "SSA" && authenticated.isAdmin == true)) {
        return superAdminRoleSet;
    }
    else {
        return [];
    }
}